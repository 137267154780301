var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    _vm._l(_vm.labelValues, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "row" },
        [
          _c("span", [_vm._v(_vm._s(item.label) + "：")]),
          item.isDirection
            ? _c("Direction", { attrs: { direction: item.value } })
            : _c("span", [_vm._v(_vm._s(item.value) + " ")]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }