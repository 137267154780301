<template>
  <div class="container">
    <div class="row" v-for="(item, index) in labelValues" :key="index">
      <span>{{ item.label }}：</span>
      <Direction v-if="item.isDirection" :direction="item.value" />
      <span v-else>{{ item.value }} </span>
    </div>
  </div>
</template>
<script>
import Direction from './direction'
export default {
  name: 'Msg',
  components: { Direction },
  props: {
    // 文本与值数组
    labelValues: {
      type: Array,
      default: () => {}
    }
  },
  watch: {},
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    span:first-child {
      min-width: 70px;
    }
  }
}
</style>
