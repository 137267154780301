var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cleaner-track" },
    [
      _c("div", { staticClass: "container" }, [
        _vm.form.address
          ? _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("所在位置：")]),
              _c("span", [_vm._v(_vm._s(_vm.form.address))]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("姓名：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.name))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("身份证号码：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.identityCard))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("设备编号：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.deviceId))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("IMEI：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.imei))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("速度：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.speed))]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("span", [_vm._v("定位时间：")]),
          _c("span", [_vm._v(_vm._s(_vm.form.time))]),
        ]),
      ]),
      _vm.showFooter
        ? _c("footer-tool", { attrs: { objectInfo: _vm.trackInfo } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }