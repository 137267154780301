<template>
  <div class="cleaner-track">
    <!-- 用组件会出现显示内容上下跳动 -->
    <!-- <Msg :labelValues="labelValues" /> -->
    <div class="container">
      <div class="row" v-if="form.address">
        <span>所在位置：</span>
        <span>{{ form.address }}</span>
      </div>
      <div class="row">
        <span>姓名：</span>
        <span>{{ form.name }}</span>
      </div>
      <div class="row">
        <span>身份证号码：</span>
        <span>{{ form.identityCard }}</span>
      </div>
      <div class="row">
        <span>设备编号：</span>
        <span>{{ form.deviceId }}</span>
      </div>
      <div class="row">
        <span>IMEI：</span>
        <span>{{ form.imei }}</span>
      </div>
      <div class="row">
        <span>速度：</span>
        <span>{{ form.speed }}</span>
      </div>
      <div class="row">
        <span>定位时间：</span>
        <span>{{ form.time }}</span>
      </div>
    </div>
    <footer-tool v-if="showFooter" :objectInfo="trackInfo" />
  </div>
</template>
<script>
import get from 'lodash/get'
import Msg from '../../../Msg'
import FooterTool from '../../../footerTool'
export default {
  name: 'MapCleanerInfoWindow',
  components: { Msg, FooterTool },
  props: {
    // 对象信息
    objectInfo: {
      type: Object,
      default: () => {}
    },
    // 轨迹信息
    trackInfo: {
      type: Object,
      default: () => {}
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {
      console.log(newVal)
    },
    trackInfo(newVal, oldVal) {
      this.buildData()
    }
  },
  data() {
    return {
      labelValues: [],
      form: {}
    }
  },
  mounted() {
    this.buildData()
  },
  methods: {
    buildData() {
      this.labelValues = []
      const addr = get(this.trackInfo, 'address', '')
      if (addr) {
        // this.labelValues.push({
        //   label: '定位地点',
        //   value: get(this.trackInfo, 'address', '')
        // })
        this.form.address = get(this.trackInfo, 'address', '')
      }
      // this.labelValues.push({
      //   label: '姓名',
      //   value: get(this.objectInfo, 'name', '')
      // })
      this.form.name = get(this.objectInfo, 'bindName', '')
      // this.labelValues.push({
      //   label: '身份证号码',
      //   value: get(this.objectInfo, 'identityCard', '')
      // })
      this.form.identityCard = get(this.objectInfo, 'bindKey', '')
      // this.labelValues.push({
      //   label: '设备编号',
      //   value: get(this.objectInfo, 'deviceId', '')
      // })
      this.form.deviceId = get(this.objectInfo, 'deviceId', '')
      // this.labelValues.push({
      //   label: 'IMEI',
      //   value: get(this.objectInfo, 'imei', '')
      // })
      this.form.imei = get(this.objectInfo, 'imei', '')
      const speed = Number(get(this.trackInfo, 'speed', 0))
      // this.labelValues.push({
      //   label: '速度',
      //   value: speed > 0 ? speed.toFixed(2) + 'km/h' : '暂无速度'
      // })
      this.form.speed = speed > 0 ? speed.toFixed(2) + 'km/h' : '暂无速度'
      // this.labelValues.push({
      //   label: '定位时间',
      //   value: get(this.trackInfo, 'deviceTime', '')
      // })

      this.form.time = this.parseDateTime(new Date(this.trackInfo.time), 'yyyy-MM-dd HH:mm:ss')
      // this.labelValues.push({
      //   label: '设备电量',
      //   value: get(this.objectInfo, 'electQuantity', '')
      // })
      // const pValue = get(this.objectInfo, 'powerValue', '')
      // this.labelValues.push({
      //   label: '电源',
      //   value: pValue ? '已接通' : '未接通'
      // })
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
}
</style>
